import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 816.000000 816.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,816.000000) scale(0.100000,-0.100000)" >
				<path d="M0 4080 l0 -4080 4080 0 4080 0 0 4080 0 4080 -4080 0 -4080 0 0
-4080z m4272 4010 c605 -34 1142 -179 1663 -449 640 -333 1174 -827 1558
-1442 530 -851 723 -1885 536 -2869 -152 -796 -534 -1517 -1108 -2091 -585
-584 -1318 -968 -2126 -1113 -857 -154 -1726 -30 -2505 359 -945 471 -1659
1285 -2006 2286 -282 816 -290 1744 -20 2554 202 604 508 1111 941 1554 710
727 1652 1156 2665 1211 200 10 210 10 402 0z"/>
<path d="M2364 6155 c-848 -122 -1420 -932 -1249 -1770 71 -346 269 -662 551
-876 52 -40 96 -70 98 -68 2 2 57 87 123 189 121 187 681 1050 801 1235 84
129 391 602 561 864 72 112 130 205 129 206 -19 18 -215 114 -288 142 -209 78
-502 110 -726 78z"/>
<path d="M2453 4363 c-304 -468 -833 -1283 -891 -1373 -321 -491 -533 -820
-538 -834 -6 -15 176 -111 281 -149 392 -143 822 -120 1186 63 275 139 530
394 669 669 271 541 166 1234 -255 1679 -72 77 -246 222 -265 222 -4 0 -89
-125 -187 -277z"/>
<path d="M6396 4358 c-46 -53 -60 -107 -64 -248 -3 -104 0 -148 12 -190 39
-133 100 -170 161 -98 42 50 60 129 60 263 -1 136 -19 214 -61 264 -32 38 -80
42 -108 9z"/>

</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
